@mixin for-mobile-up-large {
  @media (min-width: 400px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 992px) and (max-width: 1220px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin for-desktop-down {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin for-mobile-down {
  @media (max-width: 520px) {
    @content;
  }
}

@mixin for-desktop-up-large {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin hover-supported {
  @media (min-width: 992px) {
    &:hover {
      @content
    }
  }
}

@mixin focus-visible {
  &:focus-visible {
    outline: 0 none;
    box-shadow: 0 0 0 2px #000000, 0 0 0 3px #ffffff;
    border-radius: 2px;
  }
}

@mixin no-focus-visible {
  &:focus-visible {
    outline: 0 none;
    box-shadow: none;
  }
}

@mixin gradient-text {
  background-image: linear-gradient(90deg, #724FFF 0%, #00C2FF 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
}

@mixin disable-input-zoom {
  @media screen and (device-aspect-ratio: 2/3), screen and (device-aspect-ratio: 40/71), screen and (device-aspect-ratio: 375/667), screen and (device-aspect-ratio: 9/16) {
    @content;
  }
}

/* woff2 - Chrome 26+, Opera 23+, Firefox 39+ */
/* woff - Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
@mixin font-face($style, $weight, $font-name, $font-file, $font-type) {
  @font-face {
    font-family: $font-name;
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: url($path-to-static-fonts + '#{$font-file}-#{$weight}.woff2') format('woff2'),
    url($path-to-static-fonts + '#{$font-file}-#{$weight}.woff') format('woff');
    @if $font-type {
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    } @else {
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
  }
}