@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.why {
  background: #fff;

  & &__wrapper {
    padding: 24px 24px;
    background: $brand-900;
    border-radius: 16px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    @include for-desktop-up {
      padding: 60px 60px;
      border-radius: 24px;
      max-width: 100%;
    }
  }

  & &__title {
    width: 100%;
    max-width: 367px;
    color: #F2F1FF;
    text-align: left;
  }

  & &__subtitle {
    color: #F2F1FF;
    text-align: left;
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    @include for-desktop-up {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__description {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    @include for-desktop-up {
      flex: 0 1 556px;
    }
    .button {
      margin-top: 24px;

      @include for-desktop-down {
        max-width: 100%;
      }
    }
  }

  &__list {
    margin-top: 24px;

    @include for-desktop-up {
      margin-top: 28px;
      padding: 0 24px;
    }
  }

  &__list-item {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 16px 0;
    color: #F6F6FA;
    font-size: 20px;font-weight: 700;
    line-height: 26px;

    @include for-desktop-up {
      gap: 16px;
    }

    svg {
      flex: 0 0 46px;
    }
  }

  &__image {

    @include for-desktop-up {
      flex: 0 1 484px;
      max-width: 100%;
    }

    @include for-mobile-down {
      max-width: 266px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image .image {
    display: block;
    width: 100%;
    height: auto;

    @include for-mobile-down {
      max-width: 266px;
    }
  }

  .image-bottom {
    margin-top: 16px;
  }
}
