@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.product {
  background: #fff;

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 30px;

    @include for-desktop-up {
      flex-direction: row;
      justify-content: center;
      margin-top: 60px;
      gap: 20px;
    }
  }

  &__item-first {
    background: linear-gradient(280deg, #fce3f9 0%, #dcf7dd 100%);

    &:after {
      content: url("../../../public/images/home/product-1-bg.png");
      position: absolute;
      width: 232px;
      height: 219px;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__item-second {
    background: linear-gradient(100deg, #f0ecff 2.43%, #e3f0ff 101.94%);

    &:after {
      content: url("../../../public/images/home/product-2-bg.png");
      position: absolute;
      width: 232px;
      height: 219px;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__item {
    max-width: 630px;
    width: 100%;
    flex: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 24px;
    position: relative;

    @include for-desktop-up {
      min-height: 310px;
      padding: 30px;
    }

    .title {
      color: #000;
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;

      br {
        @include for-desktop-down {
          display: none;
        }
      }
    }

    .description {
      color: #767494;
      margin-top: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      z-index: 2;
    }

    .image-bottom {
      margin-top: 16px;
    }

    .button {
      margin-top: 16px;
    }
  }

  .comment {
    margin-top: 16px;
    display: grid;
    align-items: center;
    grid-template-columns: 75px 1fr;
    grid-template-rows: auto;
    gap: 16px;
    z-index: 2;

    @include for-desktop-up {
      gap: 4px 16px;
    }

    &__image {
      grid-row: 1 / span 1;
      width: 46px;
      height: 46px;

      @include for-desktop-up {
        width: 74px;
        height: 74px;
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;
      }
    }

    &__author {
      color: #020b1e;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }
    &__text {
      color: #767494;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;

      grid-column: 1 / span 2;
      grid-row: 2 / span 1;

      @include for-desktop-up {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
      }
    }
  }
}
