.policy-section {
  font-family: "Poppins", sans-serif;
  color: #000;

  &__wrapper {
    position: relative;
    margin: 0 auto;
    padding: 30px 16px;
    max-width: 630px;

    @include for-desktop-up {
      padding: 56px 16px;
      max-width: calc(1280px + 32px);
    }
  }

  &__update {
    .policy-section & {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      color: #020b1e;
    }
  }

  &__title {
    align-items: flex-start;
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__title-compatibility {
    @media (max-width: 425px) {
      font-size: 38px;
      line-height: 58px;
    }
  }

  &__text {
    color: #767494;
    font-size: 14px;
    line-height: 21px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 32px;
      color: #020b1e;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 30px;
      line-height: 39px;
      margin-bottom: 24px;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      margin-top: 34px;
      margin-bottom: 32px;
      color: #020b1e;
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
    }

    p,
    ul,
    ol {
      color: #767494;
      margin-bottom: 32px;
      list-style: disc;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.16px;

      &.small-bottom-space {
        margin-bottom: 16px;
      }
    }

    ul,
    li {
      margin: 0 0 0 20px;
    }

    b {
      font-weight: 700;
      color: #767494;
    }

    a {
      color: #767494;
      transition: 0.3s ease;

      &:hover {
        color: #4a3aff;
      }
    }

    i {
      color: #fff;
    }

    .sub-title {
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 11px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__uppercase {
    text-transform: uppercase;
  }

  &__italic {
    font-style: italic;
  }

  &__underline {
    text-decoration: underline;
  }

  ol {
    list-style: auto;
    counter-reset: item;
    list-style: none;

    li {
      margin: 0;
      counter-increment: item;

      &::before {
        content: counter(item) ". ";
        text-align: right;
        flex: 0 0 13px;
      }
    }
  }

  ul {
    margin: 0 0 32px 0;
    list-style: none;

    li {
      position: relative;

      &::before {
        content: "";
        background: #9391a6;
        display: block;
        position: absolute;
        left: -15px;
        top: 8px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }

    &.list-dash {
      li {
        margin-left: 16px;

        &::before {
          content: "-";
          background: transparent;
          top: 1px;
          left: -15px;
        }
      }
    }
  }
}

.underline {
  text-decoration: underline;
}

.definitions {
  .title {
    display: block;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  p {
    padding-left: 40px;
  }
}

table {
  margin-bottom: 16px;
}

table td,
table th {
  padding: 15px;
  border: 1px solid #767494;
}
