@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.footer {
  background: $brand-900;
  color: #f6f6fa;

  &__logo {
    width: 124px;
    height: 33px;
  }

  &__wrapper {
    padding-top: 40px;
    padding-bottom: 40px;

    @include for-desktop-down {
      max-width: 630px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;

    @include for-desktop-down {
      text-align: left;
      flex-direction: column;
      gap: 24px;
    }
  }

  &__content-left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #f6f6fa;
    font-size: 12px;
    line-height: 18px;

    @include for-desktop-up {
      flex-direction: row;
      gap: 40px;
    }
  }

  &__content-right {
    display: flex;
    flex-direction: column;
    color: #f2f1ff;
    font-size: 14px;
    line-height: 20px;

    @include for-desktop-up {
      justify-content: right;
      padding-top: 7px;
    }

    a {
      color: inherit;

      @include hover-supported {
        color: #ffcc48;
      }
    }

    p {
      @include for-desktop-up {
        text-align: right;
        margin-bottom: 6px;
      }
    }
  }

  &__content-address {
    @include for-desktop-up {
      margin-top: 8px;
    }
  }

  &__content-item {
    display: block;
    margin: 24px 0;

    @include for-desktop-up {
      margin: 10px 0 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &.address {
      margin: 12px 0 0;
    }

    a {
      color: #fff;
      font-weight: 700;

      &:hover {
        color: $button-color-hover;
      }
    }

    strong {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__content-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    padding-top: 16px;
    border-top: solid 1px rgba(242, 241, 255, 0.2);

    @include for-desktop-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }

    h4 {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  &__nav {
    padding: 24px 0;
  }

  &__list {
    display: flex;
    gap: 24px;

    @include for-desktop-up {
      justify-content: right;
    }

    a {
      color: #f6f6fa;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__copy {
    color: #f6f6fa;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    @include for-desktop-up {
      text-align: right;
    }
  }

  &__payment-list {
    display: flex;
    gap: 10px;

    @include for-desktop-down {
      flex-wrap: wrap;
    }
  }
}
