@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.button {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  width: 100%;
  max-width: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
  text-align: center;
  height: 50px;
  opacity: 1;
  border-radius: 12px;
  transition:
    background-color 0.225s ease,
    color 0.225s ease,
    opacity 0.225s ease;

  &:disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }

  @include focus-visible;

  &--main {
    background-color: $button-color;

    @include hover-supported {
      background-color: $button-color-hover;
      color: #fff;
    }

    &:active {
      background-color: $button-color-hover;
    }
  }

  &--white {
    background-color: #fafaff;
    color: #4a3aff;

    @include hover-supported {
      background-color: $yellow;
      color: #000;
    }
  }
}
