@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.hero {
  background: #fff;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @include for-desktop-up {
      padding-top: 90px;
      flex-direction: row;
      gap: 120px;
    }

    @media (max-width: 475px) {
      align-items: flex-start;
    }
  }

  &__main-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 454px;

    @include for-desktop-up {
      display: block;
      text-align: left;
    }
  }

  &__title {
    width: 100%;
    color: #020b1e;
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;

    span {
      color: $brand-900;
    }
  }

  &__description {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $inverse-700;
  }

  &__button {
    margin-top: 30px;
    max-width: 398px;

    @include for-desktop-up {
      margin-top: 40px;
      max-width: 256px;
    }
  }

  &__picture {
    max-width: 292px;

    @include for-desktop-up {
      flex: 0 1 604px;
      max-width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }
}
