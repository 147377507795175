@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.steps {
  background: #fff;

  &__wrapper {
    @include for-desktop-down {
      overflow: hidden;
    }
  }

  &__subtitle {
    margin: 0 auto;
    max-width: 454px;
  }

  &__slider {
    position: relative;
    margin-top: 60px;
    margin-bottom: 60px;

    @include for-desktop-down {
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 455px;
      overflow: inherit;
    }

    @media (max-width: 475px) {
      margin: 30px -16px;
    }
  }

  &__slide {
    background: #fafaff;
    padding: 24px 30px;
    max-width: 248px;
    height: auto;
    box-sizing: border-box;
    border: 1px solid #f2f1ff;
    border-radius: 16px;

    @include for-desktop-up {
      padding: 30px;
      max-width: 708px;
    }

    .top {
      display: flex;
      gap: 12px;
      margin-bottom: 24px;

      @include for-desktop-up {
        margin-bottom: 32px;
        gap: 16px;
      }
    }

    .title {
      color: #000;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #767494;
      margin-top: 16px;
      text-align: center;

      @include for-desktop-down {
        margin-bottom: 38px;
      }
    }

    .icon_wrap {
      text-align: center;
      margin-bottom: 28px;

      @include for-desktop-down {
        margin-top: 38px;
      }

      svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__button {
    margin: 0 auto;

    @include for-desktop-down {
      max-width: 398px;
    }
  }
}
