@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.faq-section {
  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin: 30px auto 0;
    max-width: 630px;

    @include for-desktop-up {
      max-width: 800px;
      margin: 80px auto 0;
    }

    &.is-open {
      border-radius: 16px;
      border-color: #f2f1ff;

      .faq-question h3 {
        color: #4a3aff;
      }
    }
  }

  .list-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    border: 1px solid transparent;

    &.is-open {
      border-radius: 16px;
      border-color: #f2f1ff;

      .faq-question h3 {
        color: #4a3aff;
      }
    }
  }

  .faq-question {
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: space-between;

    @include for-desktop-up {
      cursor: pointer;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      color: #000;
    }
  }

  .faq-answer {
    padding-right: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #767494;
  }

  .faq-item {
    margin-top: 16px;
  }
}
