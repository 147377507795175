// common typography settings
$base-font-size: 16px;

// header height settings
$header-height: 60px;
$header-height-mobile: 60px;

// colors settings
$white: #ffffff;
$bg-dark: #171717;
$bg-dark1: #181818;
$bg-grey: #1C1C1C;
$text: #E6E6E6;
$text-yellow: #F2B603;
$yellow: #FFCC48;
$button-color: #413aff;
$button-color-hover: #3A61FFFF;

$path-to-static-fonts: '../fonts/';

//securemyphone
$text-body-light: #767494;
$brand-900: #4A3AFF;
$inverse-700: #767494;
