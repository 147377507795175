.steps__slider {
  .swiper-wrapper {
    margin-bottom: 30px;

    @include for-desktop-up {
      margin-bottom: 0;
    }
  }

  .swiper-navigation {
    max-width: 398px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.swiper-pagination {
  position: static;

  @include for-desktop-up {
    display: none;
  }
}

.swiper-pagination {
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;

  @include for-desktop-down {
    display: flex;
  }
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #F2F1FF;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  background: #786CFF;
  opacity: 1;
}

.swiper-navigation {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;

  @include for-desktop-up {
    display: none;
  }
}

.swiper-navigation-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #786CFF;
  transition: 0.3s ease;

  &.is-disabled {
    border-color: #9c9c9c;

    svg {
      color: #9c9c9c;
    }
  }
}

.swiper-navigation-icon {
  transition: 0.3s ease;

  &.next {
    transform: rotate(180deg);
  }
}
