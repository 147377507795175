@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.features {
  background: #fff;

  & &__wrapper {
    padding: 16px 16px 24px;
    background: #ffcc48;
    border-radius: 16px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    @include for-desktop-up {
      padding: 60px 60px;
      border-radius: 24px;
      width: 100%;
      max-width: 100%;
    }

    @include for-mobile-down {
      max-width: 288px;
    }
  }

  &__title {
    text-align: left !important;
  }

  &__subtitle {
    color: #f2f1ff;
    text-align: left;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include for-desktop-up {
      flex-direction: row;
      justify-content: space-between;
      gap: 90px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    .button {
      margin-top: 16px;
    }
  }

  &__list {
    padding: 8px 0;
  }

  &__list-item {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 16px 0;
    color: #020b1e;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;

    @include for-desktop-up {
      gap: 16px;
    }
  }

  &__image {
    @include for-desktop-up {
      flex: 100%;
      max-width: 604px;
    }
  }

  &__image .image {
    display: block;
    width: 100%;
    height: auto;

    @include for-desktop-down {
      border-radius: 18px;
    }
  }

  .image-bottom {
    margin-top: 16px;
  }
}
