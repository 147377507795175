html {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: $base-font-size;
  background-color: #fff;
  color: $text;
  min-height: 100%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  flex: auto;
}

#root a {
  transition: 0.3s ease;
}

.main-wrapper {
  min-width: 0;
}

.wrapper {
  max-width: calc(1280px + 32px);
  width: 100%;
  padding: 30px 16px;
  margin-left: auto;
  margin-right: auto;

  @include for-desktop-up {
    padding: 65px 16px;
  }
}

.disabled-scroll {
  overflow-y: hidden;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  white-space: nowrap;
}

.main-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  color: #000;

  span {
    color: $brand-900;
  }
}

.main-subtitle {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #000;
}

* {
  tab-size: 4;
}
